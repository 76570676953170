// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class SMSService {
    getAllSMS() {
        return axios.get(API_URL + 'sms', { headers: authHeader() })
    }
    getCategories() {
        return axios.get(API_URL + 'notification-groups', { headers: authHeader() })
    }
    deleteSMS(resource) {
        return axios.delete(API_URL + 'sms/' + resource.id, { headers: authHeader() })
    }
    updateSMS(resource) {
        return axios.put(API_URL + 'sms/' + resource.id, resource, { headers: authHeader() })
    }
    storeSMS(resource) {
        return axios.post(API_URL + 'sms' , resource, { headers: authHeader() })
    }
}

export default new SMSService()
